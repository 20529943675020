<template>
  <CContainer class="min-vh-100 d-flex align-items-center">
    <CRow class="w-100 justify-content-center">
      <CCol md="6" sm="8">
        <CCard class="mx-4 mb-0">
          <CCardBody class="p-4">
              <h1>Create Password</h1>
              <p class="text-muted">Enter your password in the form below</p>
                <CInput
                  v-model="password"
                  placeholder="Password"
                  type="password"
                  autocomplete="curent-password"
                />
                <CInput
                  v-model="repeatPassword"
                  placeholder="Repeat Password"
                  type="password"
                />
              <CButton v-on:click="createPassword" color="success" block>Create Password</CButton>
              <p class='message errorMessage' v-html="errorMessage"></p>
              <p class='message successMessage' v-html="successMessage"></p>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>

import AuthAPI from '/app/src/api/auth.js'

export default {
    name: 'CreatePassword',
    data () {
    return {
        password: '',
        repeatPassword: '',
        successMessage: '',
        errorMessage: '',
        authAPI: new AuthAPI()
    }
  },
  methods: {
    createPassword: function () {

        this.$store.dispatch('loading')
        let self = this

        this.authAPI.createPassword(this.$route.params.code, self.password)
        .then((result) => {
            this.$store.dispatch('stopLoading')
            this.$router.push({path: '/auth/login' })
        })
        .catch((error) => {
            this.$store.dispatch('stopLoading')
            this.errorMessage = error
            this.successMessage = ''
        })
      
    },
    checkPasswords() {
        if (this.password !== this.repeatPassword) {
            this.errorMessage = 'Passwords do not match'
            this.successMessage = ''
        } else {
            this.successMessage = 'Passwords Match'
            this.errorMessage = '';
        }
    }
  },
  watch: {
    'password': {
      handler(val) {
          this.checkPasswords()
      }
    },
    'repeatPassword': {
      handler(val) {
          this.checkPasswords()
      }
    }
  }
}
</script>

<style scoped>

    .message {
        margin-top: 20px;
    }

    .successMessage {
        color: green;
    }

    .errorMessage {
        color: red;
    }

</style>